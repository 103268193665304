import styled from 'styled-components'

export const Container = styled.div`

    height: auto;
    background: #F2F3F8;

    box-shadow: 0px 1px 0px rgba(-17,-17,-26,-0.1), 0px 8px 24px rgba(-17,-17,-26,-0.1), 0px 16px 48px rgba(-17,-17,-26,0.1);

`

export const ButtonsContainer = styled.div`

    display: flex;

    & > button{
        width: 392px;
        height: 60px;
        background: #F2F3F8;
        border-radius: 4px;
    }

`