import React, { useState } from 'react'
import { Container, Left, MainText, Right, ButtonsContainer, Button} from './styles'

import WowBlock from '../../images/contact/block.png'

export default ({ setShowForm }) => {

	const [ client, setClient ] = useState(true);
	const [ newClient, setNewClient ] = useState(false);

	const handleButtonClick = (e) => {
		if (e.target.name === 'client'){
			setShowForm(true)
			setClient(true)
			setNewClient(false)
		}
		else{
			setShowForm(false)
			setClient(false)
			setNewClient(true)
		}
	}

	return(
		
		<Container>
			<div>
				<Left>
					<img src={WowBlock} alt='Block'/>
				</Left>
				<MainText>
					<span>Tem alguma <b>Dúvida OU Sugestão?</b></span>
					<span>Nos mande uma mensagem.</span>
				</MainText>
				<Right>
					<img src={WowBlock} alt='Block'/>
				</Right>
			</div>
			<div>
				<ButtonsContainer>
					<Button onClick={handleButtonClick} selected={client} name='client'>Sou cliente wow</Button>
					<Button onClick={handleButtonClick} selected={newClient} name='newClient'>Quero ser wow</Button>
				</ButtonsContainer>
			</div>
		</Container>
	)
}