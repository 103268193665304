import styled from 'styled-components'

import Arrow from '../../images/contact/arrow.png'

export const Container = styled.div`

   display: ${props => props.display? props.width : 'flex'};
   justify-content: center;
   
   & > form{
      font-family: 'Roboto';
      margin-top: 100px;
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;

      & > div {
         display: flex;
   
         @media (max-width: 1024px){
            width: inherit;
         }

         @media (min-width:320px) and (max-width: 414px){
            flex-direction: column;
         }
      }

   }
`

export const DivInputs = styled.div`

   display: flex;
   width: 100%;
   margin: 10px 20px;
   width: ${props => props.width? props.width+'%' : '100%'};

   & > label{
      display: flex;
      align-items: center;
      color: #1E3760;

      &:first-child{
         margin-right: 20px;
      }
   }

   @media (min-width:320px) and (max-width: 414px){
      width: auto;
   }

`

export const Input = styled.input`

   width: ${props => props.width? props.width+'%' : 'unset'};
   margin-right: ${props => props.right? props.right+'px' : 'unset'};

   border: 1px solid #F2F3F8;
   border-radius: 26px;
   height: 50px;
   box-sizing: border-box;
   box-shadow: ${props => props.isRadio? 'inherit' : '1px 12px 20px rgba(0, 0, 0, 0.2), 0px -4px 10px #FFFFFF, inset 1px 2px 5px rgba(0, 0, 0, 0.25)'};
   -webkit-box-shadow: ${props => props.isRadio? 'inherit' : '1px 12px 20px rgba(0, 0, 0, 0.2), 0px -4px 10px #FFFFFF, inset 1px 2px 5px rgba(0, 0, 0, 0.25)'};
   padding-left: 30px;
   font-size: 18px;
   cursor: pointer;

   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
   }

   input[type=number] {
      -moz-appearance: textfield;
   }

   &::placeholder{
      color: #1E3760;
      font-family: 'Roboto';
   }
`

export const InputPJ = styled.input`

   display: ${props => props.status? 'none' : 'flex'};
   width: ${props => props.width? props.width+'%' : 'unset'};
   margin-right: ${props => props.right? props.right+'px' : 'unset'};

   border: 1px solid #F2F3F8;
   border-radius: 26px;
   height: 50px;
   box-sizing: border-box;
   box-shadow: 1px 12px 20px rgba(0, 0, 0, 0.2), 0px -4px 10px #FFFFFF, inset 1px 2px 5px rgba(0, 0, 0, 0.25);
   padding-left: 30px;
   font-size: 18px;
   &::placeholder{
      color: #1E3760;
      font-family: 'Roboto';
   }

`

export const TextArea = styled.textarea`

   width: ${props => props.width? props.width+'%' : 'unset'};
   height: 220px;
   border: 1px solid #F2F3F8;
   border-radius: 26px;
   box-sizing: border-box;
   box-shadow: 1px 12px 20px rgba(0, 0, 0, 0.2), 0px -4px 10px #FFFFFF, inset 1px 2px 5px rgba(0, 0, 0, 0.25);
   padding-top: 10px;
   padding-left: 30px;
   font-size: 18px;
   resize: none;

   &::placeholder{
      color: #1E3760;
      font-family: 'Roboto';
   }
`

export const Button = styled.button`

   width: 178px;
   height: 40px;
   align-self: flex-end;
   border-radius: 18px;
   text-transform: uppercase;
   color: #1E3760;
   border: 1px solid #1E3760;
   box-sizing: border-box;
   box-shadow: 1px 12px 20px rgba(0, 0, 0, 0.2), 0px -4px 10px #FFFFFF, inset 1px 2px 5px rgba(0, 0, 0, 0.25);
   background-color: white;
   margin-right: 20px;

   &:hover{
      background-color: #08C1C1;
   }

`

export const Select = styled.select`

   overflow: hidden;
   width: ${props => props.width? props.width+'%' : '100%'};
   border: 1px solid #F2F3F8;
   border-radius: 26px;
   height: 50px;
   box-sizing: border-box;
   box-shadow: 1px 12px 20px rgba(0, 0, 0, 0.2), 0px -4px 10px #FFFFFF, inset 1px 2px 5px rgba(0, 0, 0, 0.25);
   padding-left: 30px;
   font-size: 18px;
   cursor: pointer;
   background-color: white;
   -webkit-transform: translate3d(0,0,0)!important;
   color: #1E3760;
   font-family: 'Roboto';
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background-image: url(${Arrow});
   background-size: 16px;
   background-position: center right 20px; 
   background-repeat: no-repeat;   


   &::-ms-expand {
      display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
   }

`