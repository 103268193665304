import styled from 'styled-components'

export const Container = styled.div`
   
   height: 600px;
   background: linear-gradient(180deg, #00879A 0%, #00BFBF 100%);
   mix-blend-mode: normal;
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: flex-end;
   padding-top: 100px;
   
   & > div{
      display: flex;

      &:first-child{
         margin-bottom: 70px;
      }

      & > div > img{
         height: 120px;

         @media (max-width: 1024px){
            height: 60px;
         }

         @media (min-width:320px) and (max-width: 414px){
            display: none;
         }
      }
   }

`

export const Left = styled.div`

   & > div { 
      display: flex;
      justify-content: flex-start;
      padding-bottom: 100px;
   }
`

export const MainText = styled.div`

   display:flex;
   flex-direction: column;
   align-items: center;

   & > span:first-child{
      color: white;
      text-align: center;
      font-size: 60px;
      text-transform: uppercase;
      margin-bottom: 60px;
   }

   & > span{
      color: #1E3760;
      font-size: 24px;
   }

`

export const Right = styled.div`
   display: flex;
   justify-content: flex-end;
   margin-top: 200px;
`

export const ButtonsContainer = styled.div`

   display: flex;

   @media(min-width: 320px) and (max-width: 414px){
      flex-direction: column;
      & > button{
         width: 250px;
         margin-bottom: 10px;
      }   
   }
`

export const Button = styled.button`

   width: 392px;
   height: 60px;
   border-radius: 4px 4px 0 0;
   color: #1E3760;
   text-transform: uppercase;
   font-size: 18px;
   text-decoration: none;
   margin-right: 10px;
   border: none;
   border-top: ${props => props.selected? '5px solid #1E3760' : 'none'}; 
   opacity: ${props => props.selected? '100%' : '60%'}; 
   background: #F2F3F8;
   cursor: pointer;
   
   &:hover{
      border: none;
   }

   &:focus{
      outline: none;
   }
   
`