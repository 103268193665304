import React, { useState } from 'react'
import Header from '../../components/header'
import HeadContact from '../../components/headContact'
import ResponsiveHead from '../../components/ResponsiveHead'
import Forms from '../../components/forms'
import Footer from '../../components/footer'

import { useStaticQuery, graphql } from 'gatsby'


export default () => {

  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(true); 

  const data = useStaticQuery(graphql`
    {
        site {
        siteMetadata {
            menuLinks {
                name
            }
        }
        }
    }`)

  return(
    <>
      <Header open={open} setOpen={setOpen} currentPage={data.site.siteMetadata.menuLinks.name} />
      <ResponsiveHead open={open} setOpen={setOpen} />
      <HeadContact setShowForm={setShowForm} />
      <Forms showForm={showForm}/>
      <Footer />
    </>
  )
}