import React from 'react'

import { Container } from './styles'
import FormContact from '../../components/formContact'
import FormClient from '../../components/formClient'

export default ({ showForm }) =>{

   return(
   
      <Container>
         {showForm? <FormContact/> : <FormClient /> }
      </Container>
   )
}