import React, { useState } from 'react'

import { Container, DivInputs, Input, InputPJ, TextArea, Button, Select } from './styles'

import PF from '../../images/contact/PF.svg'
import PFactive from '../../images/contact/PFactive.svg'
import PJ from '../../images/contact/PJ.svg'
import PJactive from '../../images/contact/PJactive.svg'

function validXAndNumbers(e){
   const re = /^[Xx0-9]+$/;
   if (!re.test(e.key)) {
     e.preventDefault();
   }
 }

export default () =>{
   
   const [ radioState, setRadioState ] = useState(true);
   const [ checkedPf, setCheckedPf ] = useState(true);
   const [ checkedPj, setCheckedPj ] = useState(false);

   return(
      <Container>
      
      <form method="post" action='//formspree.io/ti@wowsolution.com' >
         
         <div>
            <DivInputs>
               <label htmlFor='pf'>
                  <Input onClick={() => { setRadioState(true); setCheckedPj(false); setCheckedPf(true); }} name='pf' checked={checkedPf} isRadio={true} type='radio' id='pf' value='pf'/>
                  <img src={checkedPf? PFactive : PF} alt='PF' />
                  Para Você 
               </label>
               <label htmlFor='pj'>
                  <Input onClick={() => { setRadioState(false); setCheckedPj(true); setCheckedPf(false); }} name='pj' checked={checkedPj} isRadio={true} type='radio' id='pj' value='pj'/>
                  <img src={ checkedPj? PJactive : PJ } alt='PJ' />
                  Para Sua Empresa
               </label>
            </DivInputs>
            <DivInputs>
               <InputPJ placeholder='CNPJ:' type='text' onKeyPress={ (e) => {validXAndNumbers(e)} } name='cnpj' status={radioState} id='cnpj' width={100} />
            </DivInputs>
         </div>

         <div>
            <DivInputs>
               <Input placeholder='Seu nome e sobrenome:' type='text' name='name' id='' width={100} />
            </DivInputs>
            <DivInputs >
               <Input placeholder='DDD:' type='text' onKeyPress={ (e) => {validXAndNumbers(e)} } name='DDD' id='' width={30} right={10} />
         
               <Input placeholder='Número:' type='text' onKeyPress={ (e) => {validXAndNumbers(e)} } name='numero' id='' width={70} />
            </DivInputs>
         </div>

         <div>
            <DivInputs>
               <Select id='motivo'>
                  <option value=''>Motivo</option>
                  <option value='wowpay'>Tenho interesse no Wow Pay</option>
                  <option value='wowmanager'>Tenho interesse no Wow Manager</option>
                  {/* <option value='wowcred'>Tenho interesse no Wow Cred</option> */}
                  <option value='wowpay'>Tenho interesse no Wow Pay</option>
                  {/* <option value='wowtrust'>Tenho interesse no Wow Trust</option> */}
                  <option valur='comercial'>Comercial</option>
                  <option valur='financeiro'>Financeiro</option>
                  <option valur='sugestao'>Sugestão</option>
                  <option valur='atendimento'>Atendimento</option>
                  <option valur='reclamacao'>Reclamação</option>
               </Select>
            </DivInputs>
            <DivInputs>
               <Input placeholder='Seu e-mail:' type='text' name='email' id='' width={100} />
            </DivInputs>
         </div>

         <div>
            <DivInputs>
               <Select id='faturamento'>
                  <option value=''>Faturamento Mensal</option>
                  <option value='1a5'>R$ 1000,00 a R$ 5000,00</option>
                  <option value='5a10'>R$ 5000,00 a R$ 10.000,00</option>
                  <option value='10a20'>R$ 10.000,00 a R$ 20.000,00</option>
                  <option value='20a50'>R$ 20.000,00 a R$ 50.000,00</option>
                  <option value='50a100'>R$ 50.000,00 a R$ 100.000,00</option>
                  <option value='100a500'>R$ 100.000,00 a R$ 500.000,00</option>
                  <option value='+1000'>Mais de R$ 1.00.000,00</option>
               </Select>
            </DivInputs>
            <DivInputs>
               <Select id='como-conheceu' >
                  <option value=''>Como você conheceu a Wow?</option>
                  <option value='AgComercial'>Agente Comercial</option>
                  <option value='Google'>Google</option>
                  <option value='RedeSocial'>Redes Sociais</option>
                  <option value='Indicação'>Indicação</option>
                  <option value='Outros'>Outro</option>
               </Select>
            </DivInputs>
            <DivInputs width={50}>
               <Input placeholder='CEP:' type='text' onKeyPress={ (e) => {validXAndNumbers(e)} } name='CEP' id='' width={100}/>
            </DivInputs>
         </div>

         <div>
            <DivInputs>
               <Input placeholder='Logradouro: Ex: Rua,Av' type='text' name='logradouro' id='' width={100} />
            </DivInputs>
            <DivInputs>
               <Input placeholder='Cidade' type='text' name='Cidade' id='' width={100} />
            </DivInputs>
         </div>

         <div>
            <DivInputs>
               <Input placeholder='Bairro / Distrito' type='text' name='bairro' id='' width={100} />
            </DivInputs>
            <DivInputs>
               <Input placeholder='Número' onKeyPress={ (e) => {validXAndNumbers(e)} } type='text' name='numero' id='' width={100} />
            </DivInputs>
            <DivInputs>
               <Input placeholder='Complemento' type='text' name='complemento' id='' width={100} />
            </DivInputs>
         </div>
         
         <div>
            <DivInputs>
               <TextArea placeholder='Mensagem' name='message' id='message' width={100}/>
            </DivInputs>
         </div>

         <Button type='submit'>Enviar</Button>
         
      </form>
      
   </Container>
   )
}