import React, { useState } from 'react'

import { Container, DivInputs, Input, InputPJ, TextArea, Button } from './styles'

import PF from '../../images/contact/PF.svg'
import PFactive from '../../images/contact/PFactive.svg'
import PJ from '../../images/contact/PJ.svg'
import PJactive from '../../images/contact/PJactive.svg'


function validXAndNumbers(e){
   const re = /^[Xx0-9]+$/;
   if (!re.test(e.key)) {
     e.preventDefault();
   }
 }


export default () =>{
   
   const [radioState, setRadioState] = useState(true);
   const [ checkedPf, setCheckedPf ] = useState(true);
   const [ checkedPj, setCheckedPj ] = useState(false);

   return(

      <Container>
         
         <form method="post" action='//formspree.io/ti@wowsolution.com' >
            
            <div>
               <DivInputs>
                  <label htmlFor='pf'>
                     <Input onClick={() => { setRadioState(true); setCheckedPj(false); setCheckedPf(true); }} name='pf' checked={checkedPf} isRadio={true} type='radio' id='pf' value='pf'/>
                     <img src={checkedPf? PFactive : PF} alt='PF' />
                     Pessoa Física
                  </label>
                  <label htmlFor='pj'>
                     <Input onClick={() => { setRadioState(false); setCheckedPj(true); setCheckedPf(false); }} name='pj' checked={checkedPj} isRadio={true} type='radio' id='pj' value='pj'/>
                     <img src={checkedPj? PJactive : PJ} alt='PJ' />
                     Pessoa Jurídica
                  </label>
               </DivInputs>
               <DivInputs>
                  <InputPJ placeholder='CNPJ:' type='text' onKeyPress={ (e) => {validXAndNumbers(e)} }  name='cnpj' status={radioState} id='cnpj' width={100} />
               </DivInputs>
            </div>

            <div>
               <DivInputs>
                  <Input placeholder='Seu nome e sobrenome:' type='text' name='name' id='' width={100} />
               </DivInputs>
               <DivInputs >
                  <Input placeholder='DDD:' onKeyPress={ (e) => {validXAndNumbers(e)} } type='text' name='DDD' id='' width={30} right={10} />
            
                  <Input placeholder='Número:' onKeyPress={ (e) => {validXAndNumbers(e)} } type='text' name='numero' id='' width={70} />
               </DivInputs>
            </div>

            <div>
               <DivInputs>
                  <Input placeholder='Motivo:' type='text' name='motivo' id='' width={100} />
               </DivInputs>
               <DivInputs>
                  <Input placeholder='Seu e-mail:' type='text' name='email' id='' width={100} />
               </DivInputs>
            </div>
            
            <div>
               <DivInputs>
                  <TextArea placeholder='Mensagem' name='message' id='message' width={100}/>
               </DivInputs>
            </div>

            <Button type='submit'>Enviar</Button>
         </form>
         
      </Container>
   )
}